@import "../../../assets/styles/ui-kit/_variables.scss";

.sidebar {
  display: flex;
  flex-flow: column;
  grid-area: 1 / 1 / 3 / 2;
  height: max-content;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 350px;
  width: max-content;
  padding: 15px 10px;
  position: relative;
  z-index: 1001;
  background-color: $base-color-primary;
  color: $base-color-title;
  .sidebar-title {
    font-size: 24px;
    font-weight: 700;
    color: $base-color-title;
    cursor: pointer;
    min-height: 100px;
  }
  .sidebar-container {
    padding: 10px 10px;
    margin: 10px 20px;
  }
  .sidebar-item-wrapper {
    overflow-y: scroll;
  }
  a {
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:active {
      text-decoration: none;
    }
  }
  .nav-item {
    padding: "20px 15px";
    &:hover {
      cursor: pointer;
    }
    .title {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      color: $base-color-title;
      padding: 0 40px;
    }
    .title:hover {
      color: #ffffff;
    }
    .nav-active {
      color: #ffffff;
    }
  }
  .active .title,
  .active .sidebar-icon {
    color: #ffffff;
  }
  :hover + {
    .hover-show {
      display: block;
    }
  }
}

.active .sidebar-container {
  background-color: $base-color-title;
  border-radius: 8px;
  color: white;
}

.sidebar-full {
  @media (max-width: 920px) {
    position: fixed;
    transform: translate3d(-104px, 0, 0);
  }
}

.sidebar-mini {
  @media (max-width: 920px) {
    position: fixed;
    transform: translate3d(0, 0, 0) !important;
  }
}

.sidebar-item {
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  color: $base-color-title;
}

.sidebar-item:hover,
.sidebar-item:hover .sidebar-icon,
.sidebar-icon:hover + .title {
  color: white !important;
}

.sidebar-icon {
  color: $base-color-title;
}

.icon-modal {
  color: #a5a5c0;
  font-size: 40px;
  margin-top: 5px;
  margin-right: 29px;
}

.backdrop {
  position: fixed;
  height: max-content;
  min-height: 100vh;
  max-height: 100%;
  width: 100vw;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s linear;
}

.back-show {
  top: 0;
  left: 104px;
  opacity: 0.4;
  pointer-events: all;
}

.hover-show {
  display: none;
}

.profile-menu {
  display: flex;
  padding: 25px 25px 5px;
  min-height: 55px;
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 2px white;
    object-fit: cover;
    margin-top: 5px;
  }
  .profile-info {
    color: #ffffff;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    display: none;
    .profile-name {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 7px;
    }
    .profile-position {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
}

.notification-menu {
  position: absolute;
  min-height: 100px;
  min-width: 200px;
  width: max-content;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 32px -8px rgba(0, 0, 0, 0.25);
  color: #242726;
  left: 75px;
  top: 8px;
  padding: 14px 0px;
  z-index: 999;
  .notification-detail {
    display: flex;
    flex-direction: column;
    margin-right: 60px;
  }
  .notification-info {
    margin-top: 0;
    margin-bottom: 11px;
    color: #242726;
    font-size: 14px;
  }
  .notification-date {
    font-size: 12px;
    margin: 0;
  }
}

.notification-indikator {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
}

.notification-active {
  position: absolute;
  left: 45px;
  top: 15px;
}

.notification-title {
  font-size: 18px;
}

.notification-subtitle {
  font-size: 14px;
  margin-top: 10px;
}

.notification-menu .notification-open,
.notification-menu .notification-item {
  padding: 10px 30px;
  color: #242726;
}
