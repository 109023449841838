.rti--container {
  background: #fff !important;
  border-radius: 6px !important;
  border: 1px solid #dedede !important;
  padding: 15px 25px !important;
  text-align: left !important;
  letter-spacing: 0px !important;
  color: #222d17 !important;
  margin: 7px 0 15px !important;
}

.rti--input {
  background: #fff !important;
}

.rti--tag {
  background-color: #63ae5c !important;
  color: white !important;
  border-style: none !important;
  font-size: 14px !important;
  display: inline-block !important;
  padding: 7px 12px 7px 14px !important;
  border-radius: 20px !important;
  margin: 5px !important;
}

.rti--tag button {
  color: #fff !important;
  margin-left: 5px !important;
  cursor: pointer !important;
}

.rti--tag button:hover {
  color: #fff !important;
}

.rti--label {
  font-size: 14px !important;
  font-family: "PlusJakartaSans-Bold" !important;
  margin-top: 12px !important;
  font-weight: 600 !important;
}