.img-account {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
}

.header-container {
    display: none;
    @media(max-width: 920px) {
        display: block;
        position: absolute;
        top: 0;
        width: min-content;
        background-color: transparent;
        min-height: 50px;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        align-items: stretch;
        text-align: left;
        z-index: 99;
    } 
    div {
        display: flex;
        align-items: center;
    }
    &:first-child {
        .header-icon {
            display: none;
            cursor: pointer;
            @media(max-width: 920px) {
                display: inline;
                font-size: 34px;
            } 
        }
    }
}

.header-menu *{
    margin-right: 8px;
}

.header-name{
    width: max-content;
    padding-left: 25px;
    padding-right: 15px;
    margin: 0;
    @media(max-width: 920px) {
        width: 100px;
        overflow-x: hidden ;
        padding-left: 5px;
        padding-right: 5px;
    }
}