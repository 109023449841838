// CARD
.card-shadow {
  box-shadow: 0 4px 8px #60617029;
}
.card {
  background-color: #fff;
  border: 1px solid #e5e5f0;
  border-radius: 12px;
  // color: #6c7580;
  position: relative;
  margin-bottom: 30px;
  .card-header {
    background-color: inherit;
    color: #63ae5c;
    border-bottom: none !important;
    display: flex;
    font-weight: bold;
    line-height: 30px;
    min-height: 70px;
    padding: 32px 32px 5px;
  }
  .card-header-dashboard {
    background-color: inherit;
    color: rgb(165, 165, 192);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
    font-weight: bold;
    line-height: 30px;
    padding: 10px;
  }
  .card-header-inactive {
    background-color: inherit;
    color: rgb(31, 49, 37);
    border-bottom: none !important;
    display: flex;
    font-weight: bold;
  }
  .card-body {
    color: #3e3e3e;
    padding: 24px 32px;
    background-color: inherit;
  }
  .card-body-green {
    color: #3e3e3e;
    padding: 0px 14px 24px 14px;
    background-color: #ecf5eb;
    border: solid 1px #63ae5c;
  }
  .card-footer {
    padding: 24px 0;
    background-color: inherit;
    border: none;
  }
  .left {
    text-align: left !important;
  }
  .center {
    text-align: center !important;
  }
  .right {
    text-align: right !important;
  }

  .bg-light-secondary {
    background-color: #F7F7FA;
  }
}

.card-flush {
  padding: 16px 0;
  justify-content: center;
  color: #242726;
  .card-flush-title {
    font-family: "PlusJakartaSans-Bold";
  }
  .card-flush-desc {
    font-weight: 500;
  }
  .card-flush-icon {
    color: #242726;
  }
  .report {
    padding: 0 30px;
    .report-number {
      margin: 0 0 10px;
      font-size: 36px;
      font-weight: 400;
      line-height: 45px;
    }
    .report-desc {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .conclusion {
    padding: 0 30px;
    .conclusion-title {
      font-weight: 700;
    }
    .conclusion-desc {
      padding: 14px 0;
      font-weight: 400;
    }
  }
  .right-icon {
    color: #a5a5c0;
  }
}

.flush-status-info {
  padding: 16px 26px;
  background-color: #f8f8fb;
  color: #102316;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.orange-indicator {
  margin: 0 30px;
  border-left: 3px solid #fd9517;
}
.red-indicator {
  margin: 0 30px;
  border-left: 3px solid #e01640;
}
.container-pill {
  margin: 0 32px 24px;
}
.pill {
  border: 1px solid #e5e5f0;
  border-radius: 16px;
  color: #242726;
}
.bg-green {
  color: #6ac12f;
  border: 1px solid rgba(106, 193, 47, 0.3);
  border-radius: 8px;
  background-color: #f0f9ea;
}
.bg-red {
  color: #e01640;
  border: 1px solid rgba(224, 22, 64, 0.3);
  border-radius: 8px;
  background-color: #f9eaea;
}
.bg-yellow {
  color: #edaa21;
  border: 1px solid rgba(224, 210, 22, 0.3);
  border-radius: 8px;
  background-color: #fdf9df;
}

// Card Diagram
.text-wrapper-card-xl {
  height: 900px;
  padding: 426px 5px;
}
.text-wrapper-card-lg {
  height: 511px;
  padding: 240px 5px;
}
.text-wrapper-card-md {
  height: 357px;
  padding: 178px 5px;
}
.text-wrapper-card-sm {
  height: 233px;
  padding: 80px 5px;
}
.text-wrapper-card-strecth {
  padding: 25.7px 5px;
}

.rounded-label {
  border: #e5e5f0 solid 2px;
  border-radius: 50px;
  width: fit-content !important;
  padding: 1%;
  max-width: 100% !important;
}

.green-label {
  background-color: #daecd8;
  border: solid #90c58b 1px;
  color: #90c58b;
  padding: 5px;
  border-radius: 3px;
}
