// UI Kit - Q1 2021
// Add "Title-Subtitle" based on design

// Typography - Color
$base-color-primary: #63ae5c;
$base-color-secondary: #dedede;
$base-color-success: #74c56e;
$base-color-info: #04aa594d;
$base-color-warning: #edaa21;
$base-color-danger: #d10d0d;
$base-color-light: #e3eaef;
$base-color-dark: #5a5a5a;
$base-color-grey: #b8b8d6;
$base-color-title: #ffffff4a;
$base-color-disabled: #04aa5980;
$base-color-primary-hover: #96e79e;
$base-color-primary-seer: #4894fe1a;

// Typography - Text Color
$text-primary: #63ae5c;
$text-secondary: #a5a5a5;
$text-success: #74c56e;
$text-info: #04aa594d;
$text-warning: #d10d0d;
$text-light: #dedede;
$text-dark: #5a5a5a;

// Color For Bootstrap
$theme-colors: (
  "primary": $base-color-primary,
  "secondary": $base-color-secondary,
  "success": $base-color-success,
  "info": $base-color-info,
  "warning": $base-color-warning,
  "danger": $base-color-danger,
  "light": $base-color-light,
  "dark": $base-color-dark,
);

$btn-focus-width: none;
$btn-focus-box-shadow: none;
